import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image/dist/src/components/hooks';
import BlogPage, { BlogSectionWithImage, BlogTextSection, BlogTextTitle } from '../../components/BlogPage';
import { StaticImage } from 'gatsby-plugin-image';

const ReasonsToGetStarted = () => {
  const { placeholderImage }: { placeholderImage: ImageDataLike } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "blog/blog-2.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <Seo title="3 Gründe, um in alternative Anlagen zu investieren" />
      <BlogPage
        image={placeholderImage}
        title={'3 Gründe, um in alternative Anlagen zu investieren'}
        subtitle="Sichere Werte"
        duration={'Lesezeit: 4min'}
      >
        <BlogTextSection>
          «Aktien, Obligationen, Kryptowährungen, Fonds, Vorsorgelösungen… und jetzt sagt ihr mir, ich soll mich nochmal
          über etwas informieren?» Ja, tun wir. Aus guten Gründen. Drei sogar.
        </BlogTextSection>

        <BlogTextTitle title="1. Du diversifizierst dein Portfolio" />
        <BlogTextSection>
          Wie der Opa schon gesagt hat: Lege nicht alle Eier in einen Korb. Das heisst nicht nur, dass du verschiedene
          Aktien kaufen sollst. Gibts einen Börsencrash, bist du trotzdem der Gelackmeierte. Alternative Anlagen sind in
          dieser Zeit besonders stabil. Die Entwicklung des Luxus-Uhrenmarkts während der Coronakrise ist das beste
          Beispiel. Frag deinen Freund, der keine Rolex mehr kriegt – der kanns dir bestätigen.
        </BlogTextSection>
        <BlogTextSection>
          Wenn du neben traditionellen Finanzprodukten auch einige Splints von unseren Anlagen besitzt, minimierst du
          dein Risiko und schützt dich vor Kursschwankungen. Rezessionen werden plötzlich um einiges weniger gefährlich
          für dein Erspartes.
        </BlogTextSection>

        <BlogTextTitle title="2. Bessere Performance als (die meisten) Aktien" />
        <BlogSectionWithImage
          contentSection={
            <>
              <BlogTextSection>
                Okay, alternative Anlagen erfordern einen hohen Pflegeaufwand. Richtige Lagerung, Versicherungen, etc.
                Aber hey, das erledigen ja wir, damit du von den Vorteilen profitieren kannst! Und einer davon sind die
                sehr soliden Renditen.
              </BlogTextSection>
              <BlogTextSection>
                Lasst uns kurz beobachten, wie sich die Preise für solche Anlagen im letzten Jahrzehnt entwickelt haben.
                Laut des <a href="https://www.knightfrank.com/wealthreport">Knight Frank Reports</a> haben die
                Kategorien Whiskey (+564%), Autos (+194%), Wein (+120%) und Kunst (+141%) in den letzten 10 Jahren
                besonders zugelegt. Nicht schlecht, oder? Natürlich ist es auch bei alternativen Anlagen so, dass man
                die richtigen auswählen muss, um zu profitieren. Aber hey, auch das übernehmen wir für dich. Nice, oder?
              </BlogTextSection>
            </>
          }
          image={
            <StaticImage
              src="../../images/blog/blog-2-inner.png"
              alt="2. Bessere Performance als (die meisten) Aktien"
            />
          }
        />

        <BlogTextTitle title="3. Alternative Anlagen sind greifbarer" />
        <BlogTextSection>
          Eigentlich ein simpler Punkt, aber einer der wichtigsten. Klar, du kennst Tesla oder Apple, aber so richtig
          greifbar sind Aktien oder ETFs auch nicht, oder? Wenn dir Wertschriften bisher immer etwas zu abstrakt und
          unsicher waren, dann sind Splints genau das Richtige für dich.
        </BlogTextSection>
        <BlogTextSection>
          Selbst wenn du dich nicht mit Uhren auskennst: Du weisst, dass da eine echte Uhr bei uns im Lager steht. Und
          du weisst auch, was eine Luxus-Uhr etwa wert ist. Und wenn du dich mit Uhren auskennst, umso besser! Dann
          erwirbst du einen Splint von etwas, das du selber auch wirklich cool findest.
        </BlogTextSection>
        <BlogTextSection>
          Natürlich gibts noch viele weitere Gründe, aber am besten findest du sie gleich selbst raus, indem du die
          Splint App downloadest und die Welt der alternativen Anlagen entdeckst. Bis gleich!
        </BlogTextSection>
      </BlogPage>
    </Layout>
  );
};

export default ReasonsToGetStarted;
